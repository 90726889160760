export const environment = {
  production: false,
  apiURL: 'https://devservices.sequation.net',
  logo: `./assets/logo/tvpcc_new.png`,
  appTitle: 'TVPCC',
  appInsights: {
    instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b',
    disableExceptionTracking: true
  },
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  fileSizeLimit: '1GB'
};
